import axios from "axios";

export class App {
    constructor() {
        this.addEventListeners();
        this.saveData();
    }

    addEventListeners() {
        const myForm = document.getElementById("form");
        myForm.addEventListener("submit", (e) => this.submitForm(e));
    }

    submitForm(e) {
        e.preventDefault();
        const firstName = document.getElementById("fname").value;
        const lastName = document.getElementById("lname").value;
        const email = document.getElementById("email").value;
        const company = document.getElementById("company").value;
        const position = document.getElementById("position").value;
        const obj = {
            firstName,
            lastName,
            email,
            company,
            position,
        };

        this.saveData(obj);
    }

    saveData(data) {
        if (!data) return;

        axios({
            method: "post",
            url: "/save/index.php",
            data: JSON.stringify(data),
            config: { headers: { "Content-Type": "multipart/form-data" } },
        })
            .then(() => {
                //handle success
                window.open(
                    "../public/accelerate-5g-adoption-with-cloud-gaming.pdf",
                    "_blank"
                );
            })
            .catch((response) => {
                //handle error
                alert("Something went wrong, please try again later");
                console.log("error", response);
            });
    }
}
